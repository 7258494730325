// Generated by Framer (2add0ca)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["UeUcTHLXa"];

const variantClassNames = {UeUcTHLXa: "framer-v-19nc9df"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const transformTemplate = (_, t) => `translate(-50%, -50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; title?: string }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "UeUcTHLXa", title: wdYzwXbz6 = "Click", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "UeUcTHLXa", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-pEj3R", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-19nc9df", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"UeUcTHLXa"} ref={ref} style={{...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p><motion.span style={{"--font-selector": "R0Y7RE0gU2Fucy01MDA=", "--framer-font-family": "\"DM Sans\", sans-serif", "--framer-font-size": "20px", "--framer-font-weight": "500", "--framer-text-color": "var(--extracted-1w3ko1f)"}}>Click</motion.span></motion.p></React.Fragment>} className={"framer-15a6ug8"} fonts={["GF;DM Sans-500"]} layoutDependency={layoutDependency} layoutId={"WP6uAHquQ"} style={{"--extracted-1w3ko1f": "rgb(255, 255, 255)", "--framer-paragraph-spacing": "0px"}} text={wdYzwXbz6} transformTemplate={transformTemplate} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-pEj3R [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-pEj3R .framer-16gslrx { display: block; }", ".framer-pEj3R .framer-19nc9df { height: 24px; overflow: hidden; position: relative; width: 46px; }", ".framer-pEj3R .framer-15a6ug8 { flex: none; height: auto; left: 50%; overflow: visible; position: absolute; top: 50%; white-space: pre; width: auto; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 24
 * @framerIntrinsicWidth 46
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"wdYzwXbz6":"title"}
 */
const FramerFolEUKH23: React.ComponentType<Props> = withCSS(Component, css, "framer-pEj3R") as typeof Component;
export default FramerFolEUKH23;

FramerFolEUKH23.displayName = "Primary button";

FramerFolEUKH23.defaultProps = {height: 24, width: 46};

addPropertyControls(FramerFolEUKH23, {wdYzwXbz6: {defaultValue: "Click", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerFolEUKH23, [{family: "DM Sans", moduleAsset: {localModuleIdentifier: "local-module:canvasComponent/FolEUKH23:default", url: "https://fonts.gstatic.com/s/dmsans/v14/rP2tp2ywxg089UriI5-g4vlH9VoD8CmcqZG40F9JadbnoEwAkJxhTmf3ZGMZpg.ttf"}, style: "normal", url: "https://fonts.gstatic.com/s/dmsans/v14/rP2tp2ywxg089UriI5-g4vlH9VoD8CmcqZG40F9JadbnoEwAkJxhTmf3ZGMZpg.ttf", weight: "500"}])